/* stylelint-disable font-family-no-missing-generic-family-keyword */
/* stylelint-disable font-family-name-quotes */
.about-spice-session {
  padding: 140px 80px;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-blue);
  align-items: center;
}

.spice-slide-container {
  width: 100%;
  max-width: 1250px;
}

.about-memory {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1250px;
}

.memory-text {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 40px;
  color: white;
}

.about-journey-heading {
  text-align: center;
}

.about-origin-session {
  position: relative;
  width: 100vw;
  padding: 140px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  gap: 16px;
  background-image: url("../../public/assets/General/RedBackground2.png");
}

.about-origin-text {
  margin-block: 0 0;
  max-width: 920px;
  color: #fffaf2;
}

section {
  position: relative;
  z-index: 1; /* needed for setting pseudo-element z-index */
  overflow: hidden;
  backface-visibility: hidden;
}

section a {
  position: relative;
  display: inline-block;
  outline: none;
  color: white;
  vertical-align: bottom;
  text-decoration: none;
  white-space: nowrap;
}

.svg-handwritten a svg.svg-handwritten {
  position: absolute;
  top: 100%;
  left: 0;
  overflow: hidden;
  margin: 0;
  width: 100%;
  height: 20px;

  /* transition: stroke-dashoffset 0.3s ease-in-out; */
  transform: translateY(-90%);

  /* fill: none;
  stroke: #b1d474;
  stroke-width: 5;
  stroke-dasharray: 400px;
  stroke-dashoffset: 400px; */
}

.underline {
  width: 214px;
  height: 5px;
  fill: none;
}

.about-origin-logo {
  width: 214.173px;
  height: 84.445px;
  transform: rotate(-5.51deg);
  transform-origin: 0 0;
  background-size: contain;
}

.about-process-session {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  display: inline-flex;
  background-color: var(--bg-beige);
  padding: 100px 0;
}

.about-process-card-container {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
}

.about-process-card-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-process-card {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: inline-flex;
}

.about-process-card-image {
  width: 100%;
  max-width: 199px;
  aspect-ratio: 1/1;
  background-size: contain;
}

.about-process-card-text {
  text-align: center;
  color: rgb(69 69 69);
  font-size: 20px;
  font-family: "League Spartan";
  font-weight: 400;
  line-height: 26px;
  overflow-wrap: break-word;
  max-width: 104px;
}

.about-green-session {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
}

.about-green-heading-container-inner {
  display: flex;
  align-items: center;
}

.about-green-heading {
  text-align: left;
  color: white;
}

.about-green-number-card-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
}

.about-green-number-card {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  color: white;
  max-width: 260px;
}

.about-green-number {
  font-family: "Akbar";
  font-weight: 400;
  overflow-wrap: break-word;
  letter-spacing: 0.2rem;
}

.about-green-number-text {
  font-size: 20px;
  font-family: "League Spartan";
  font-weight: 400;

  /* line-height: 32px; */
  overflow-wrap: break-word;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .about-spice-session {
    padding: 100px 24px;
    gap: 72px;
  }

  .about-memory {
    flex-direction: column;
  }

  .about-origin-session {
    padding: 64px 0;
  }

  .about-origin-text {
    padding: 24px;
  }

  .about-letter-session {
    display: flex;
    flex-direction: column-reverse;
  }

  .about-letter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: justify;
  }

  .about-letter-text-container {
    padding: 40px 24px;
  }

  .about-letter-image {
    width: 100%;
  }

  .about-process-session {
    padding: 72px 24px;
  }

  .about-green-heading-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
  }

  .about-green-heading {
    max-width: 313px;
  }

  .about-green-number-card {
    align-items: flex-start;
    width: 46%;
  }

  .about-green-number-card-container {
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
  }

  .about-green-number {
    font-size: 52px;
  }

  .about-green-number-text {
    font-size: 18px;
    text-align: left;
  }

  .about-green-session {
    padding: 0 24px;
    aspect-ratio: var(--image-mobile-vertical);
  }

  .memory-text {
    gap: 20px;
    margin-bottom: 24px;
  }

  .memory-img {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .about-spice-session {
    padding: 40px;
    gap: 72px;
  }

  .about-memory {
    flex-direction: column;
  }

  .memory-text {
    gap: 20px;
    margin-bottom: 24px;
  }

  .memory-img {
    width: 100%;
  }

  .about-origin-session {
    padding: 40px 0;
  }

  .about-origin-text {
    width: 80vw;
  }

  .about-letter-session {
    display: flex;
    flex-direction: column-reverse;
  }

  .about-letter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    width: 70vw;
    margin: auto;
    max-width: 676px;
  }

  .about-letter-text-container {
    padding: 112px 0;
  }

  .about-letter-image {
    object-fit: cover;
    width: 100%;
  }

  .about-green-session {
    width: 100vw;
    aspect-ratio: var(--image-horizontal);
  }

  .about-green-heading-container {
    flex-grow: 1;
    padding: 0 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
  }

  .about-green-heading {
    text-align: center;
  }

  .about-green-number {
    font-size: 44px;
  }

  .about-process-session {
    padding: 40px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .about-spice-session {
    gap: 160px;
  }

  .about-letter-session {
    flex-direction: row;
  }

  .about-letter {
    width: 60vw;
  }

  .about-letter-image {
    width: 50vw;
    object-fit: cover;
  }

  .about-green-heading-container {
    flex-direction: row;
    max-width: 1440px;
  }

  .about-green-heading {
    text-align: left;
    width: 25vw;
  }

  .about-green-number-card-container {
    justify-content: flex-end;
    gap: 8px;
  }

  .about-memory {
    flex-direction: row;
  }

  .memory-text {
    gap: 44px;
    width: 60vw;
  }

  .memory-img {
    width: 30%;
  }

  .about-process-session {
    width: 100vw;
    padding: 100px 0;
  }

  .about-green-session {
    width: 100vw;
    aspect-ratio: var(--image-horizontal);
  }
}

/* Large devices (laptops/desktops, 992px and up) ipad air */
@media only screen and (min-width: 992px) {
  .about-letter {
    width: 50vw;
  }

  .about-green-number {
    font-size: 60px;
  }

  .about-spice-session {
    padding: 160px 80px;
  }

  .memory-text {
    width: 50vw;
  }

  .memory-img {
    max-width: 370px;
  }

  .about-origin-session {
    padding: 140px 0;
  }

  .about-green-heading {
    max-width: 313px;
  }

  .about-green-number-card-container {
    gap: 24px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .about-spice-session {
    gap: 320px;
  }
  .about-green-number {
    font-size: 64px;
  }
}
