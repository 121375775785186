/* stylelint-disable font-family-no-missing-generic-family-keyword */
/* stylelint-disable font-family-name-quotes */
:root {
  --medium: 75%;
}

.text-button {
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  justify-content: center;
  align-items: center;
}

.food-graphic {
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.5s ease-in-out;
}

.food-graphic-zoom {
  transform: scale(1.05);
  transition: all 0.5s ease-in-out;
}

.food-graphic-container {
  position: relative;
  height: 586px;
  width: 60vw;
  background-image: url("../../public/assets/Home/FoodSlide/mamcom.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center 40px;
}

.food-graphic-container::-webkit-scrollbar {
  display: none;
}

.food-graphic-background {
  position: absolute;
  z-index: -1;
  object-fit: cover;
  left: 0;
}

.home-moi-com-heading-container {
  position: absolute;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-moi-com-heading {
  text-align: center;
  color: var(--murrey);
  font-family: "Akbar";
  font-size: 100px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
}

.food-container {
  position: relative;
  margin: auto;
}

.slide-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-button-container {
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
  flex-direction: row;
}

.content-container {
  max-width: 620px;
}

.content-heading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-text-container {
  height: 120px;
  display: flex;
  align-items: center;
}

.stepper {
  display: inline-flex;
  justify-content: center;
  gap: 24px;
  margin-top: 40px;
}

.step {
  font-size: 14px;
  font-family: "League Spartan";
  text-transform: uppercase;
  letter-spacing: 0.7px;
}

.content {
  display: flex;
  justify-content: center;
  width: 100%;
}

.space-upper-container {
  z-index: 30;
}

.space-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: auto;
}

.space-slide-container {
  overflow: hidden;
  display: flex;
  height: 100%;
  aspect-ratio: var(--image-mobile-vertical);
  max-width: 360px;
  width: 40vw;
  margin: auto;
}

.space-slide-container-mobile {
  position: relative;
  top: 30%;
}

.image-preview-container {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin: 32px;
}

.image-preview {
  width: 50px;
  object-fit: cover;
  aspect-ratio: 1/1;
}

.image-preview-default {
  opacity: 0.5;
  transition: all 0.2s ease;
}

.image-preview-active {
  opacity: 1;
  transition: all 0.2s ease;
}

.swipe-container {
  margin: 0;
  overflow: scroll;
  scroll-snap-type: x mandatory;
  display: flex;
  position: relative;
}

.swipe-container::-webkit-scrollbar {
  display: none;
}

.swipe-image {
  width: 100vw;
  object-fit: cover;
  aspect-ratio: var(--image-mobile-horizontal);
  scroll-snap-align: center;
}

.swipe-image-vertical {
  width: 100vw;
  object-fit: cover;
  aspect-ratio: var(--image-mobile-vertical);
  scroll-snap-align: center;
}

/* animation */
.fade-in {
  opacity: 1;
  transition: opacity 0.2s ease;
}

/* x2 time so that the animation do not fade to blank */
.fade-out {
  opacity: 0;
  transition: opacity 0.4s ease;
}

.custom-button-slide-default {
  color: var(--ink60);
  font-weight: 500;
  transition: all 0.2s ease;
}

.custom-button-slide-active {
  color: var(--bg-red);
  font-weight: 500;
  transition: all 0.2s ease;
}

@media only screen and (max-width: 600px) {
  .content {
    flex-direction: column;
  }

  .food-graphic-container {
    height: 255px;
    background-size: 98%;
    background-position: center 10px;
    width: 80vw;
  }

  .home-moi-com-heading-container {
    align-items: flex-start;
    top: 30%;
  }

  .home-moi-com-heading {
    font-size: 36px;
  }

  .food-graphic {
    width: 100%;
  }

  .content-heading-container {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    height: 80px;
  }

  .content-text-container {
    height: 180px;
  }

  .stepper {
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 8px;
    max-width: 300px;
  }
}

@media only screen and (min-width: 600px) {
  .content {
    flex-direction: column;
  }

  .food-container {
    position: relative;
    margin: auto;
  }

  .food-graphic-container {
    height: 328px;
    background-size: 95%;
    background-position: center 10px;
  }

  .text-button-container {
    gap: 24px;
  }

  .review-container {
    padding: 0 40px;
  }

  .home-moi-com-heading-container {
    align-items: flex-start;
    top: 30%;
  }

  .home-moi-com-heading {
    font-size: 52px;
  }

  .food-graphic {
    width: 100%;
  }

  .content-heading-container {
    margin-bottom: 8px;
    overflow: hidden;
  }

  .stepper {
    margin-top: 32px;
  }
}

@media only screen and (min-width: 768px) {
  .home-moi-com-heading {
    font-size: 64px;
  }

  .food-graphic-container {
    height: 440px;
  }

  .text-button-container {
    gap: 40px;
  }

  .review-container {
    padding: 0;
  }

  .content {
    flex-direction: row;
  }

  .food-container {
    position: relative;
    height: 100%;
    margin: auto;
  }

  .content-heading-container {
    height: 80px;
  }

  .stepper {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 992px) {
  .home-moi-com-heading {
    font-size: 80px;
  }

  .food-graphic {
    width: 100%;
  }

  .food-graphic-container {
    height: 486px;
    background-size: 95%;
    background-position: center 24px;
  }

  .text-button-container {
    gap: 108px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .home-moi-com-heading {
    font-size: 100px;
  }

  .food-graphic-container {
    height: 70vh;
  }
}

@media only screen and (min-width: 1536px) {
  .space-slide-container {
    max-width: 600px;
  }
}
