/* stylelint-disable font-family-no-missing-generic-family-keyword */
/* stylelint-disable font-family-name-quotes */
.blog-highlight-session {
  position: relative;
  max-height: 800px;
  overflow: hidden;
}

.more-blog-container {
  padding-top: 120px;
}

.category-container {
  display: inline-flex;
  justify-content: center;
  gap: 24px;
}

.category-container-mobile {
  display: inline-flex;
  gap: 24px;
  margin: 0 24px;
  width: 90vw;
  overflow: scroll;
}

.category-container-mobile::-webkit-scrollbar {
  display: none;
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .more-blog-container {
    padding: 32px 0;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .more-blog-container {
    padding: 44px 0;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 769px) {
}

/* Large devices (laptops/desktops, 992px and up) ipad air */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
