/* stylelint-disable font-family-no-missing-generic-family-keyword */
/* stylelint-disable font-family-name-quotes */
.navbar {
  width: 100vw;
  background-color: transparent;
  z-index: 10;
  position: sticky;
  top: 0;
}

.navbar-default {
  background-color: transparent;
}

.navbar-scroll {
  background-color: white;
  transition: all 0.2s ease-in;
}

.display-navbar-none {
  display: none;
  transition: all 0.2s ease-in-out;
}

.display-navbar-block {
  opacity: 1;
  transition: all 0.2s ease-in-out;
  z-index: 5;
}

.navbar-grid {
  justify-content: space-between;
  padding: 0;
  margin: 8px 80px;
  display: flex;
}

.navbar-logo-grid {
  display: flex;
}

.navbar-logo-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.header-logo {
  width: 172px;
  height: 100%;
  aspect-ratio: 172/68;
  cursor: pointer;
}

.header-logo-drawer {
  width: 142px;
  height: 100%;
  aspect-ratio: 172/68;
  cursor: pointer;
}

.header-logo-dark {
  background-image: url("../assets/General/HeaderLogoBlack.png");
}

.header-logo-light {
  background-image: url("../assets/General/HeaderLogoWhite.png");
}

.navbar-button-grid {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  width: 10vw;
}

.button-logo {
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
}

.pages {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  color: white;
  max-width: 800px;
}

.drawer-pages {
  align-items: flex-start;
  display: flex;
  color: white;
  flex-direction: column;
  padding: 16px 24px 8px;
}

.mobile-pages {
  display: flex;
  flex-wrap: wrap;
  padding: 32px 0;
}

.mobile-page {
  width: 50%;
  padding-top: 0;
}

.mobile-page:hover,
.mobile-page-active {
  text-decoration: underline;
  text-underline-offset: 8px;
}

.custom-button-navbar-mobile {
  font-size: 26px;
  line-height: 32px;
}

.page {
  padding-top: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

.navbar-container-2 {
  height: 32px;
  justify-content: flex-start;
  align-items: center;
  gap: 28px;
  display: inline-flex;
}

.language {
  white-space: nowrap;
  display: inline-flex;
  gap: 4px;
}

.drawer-language {
  gap: 20px;
  padding: 0 10px;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
}

.button-language-container-mobile {
  gap: 8px;
  align-items: center;
  display: inline-flex;
}

.drawer-container {
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  gap: 12px;
}

.language-button {
  color: var(--ink90);
  font-size: 15;
  font-family: "League Spartan";
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.75;
  word-wrap: break-word;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .header-logo {
    width: 12vw;
  }

  .header-logo-dark,
  .header-logo-light {
    background-size: contain;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .header-logo-dark,
  .header-logo-light {
    background-size: contain;
    background-position: center;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .pages {
    width: 100%;
  }

  .header-logo {
    width: 140px;
  }

  .navbar-grid {
    margin: 8px 40px;
  }
}

/* Large devices (laptops/desktops, 992px and up) ipad air */
@media only screen and (min-width: 992px) {
  .pages {
    width: 70%;
  }

  .page {
    padding: 8px 6px 0;
  }

  .header-logo {
    width: 172px;
  }

  .navbar-grid {
    margin: 8px 80px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .page {
    padding: 12px 16px 0;
  }
}
