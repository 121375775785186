/* space community */
.space-community-session {
  position: relative;
}

.space-community-text-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.space-community-heading {
  max-width: 480px;
}

/* khu tap the graphic */
.khu-tap-the-graphic {
  width: 100vw;
  position: relative;
  aspect-ratio: 4/3;
}

.stair {
  width: 100vw;
  position: absolute;
  top: -170px;
  left: 0;
  background-image: url("../../public/assets/Space/stair2.png");
  background-size: cover;
  background-repeat: no-repeat;
  aspect-ratio: 1/1;
}

.treo-quan-ao {
  height: 498px;
  top: 22%;
  position: absolute;
  left: 0;
  object-fit: cover;
}

.space-outside {
  width: 30vw;
  position: relative;
  left: 55%;
  top: 30%;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.space-outside-image {
  width: 100%;
  aspect-ratio: var(--image-mobile-vertical);
  object-fit: cover;
}

@keyframes shadows {
  0% {
    box-shadow: 0 0 50px 1px #e0a74875;
  }

  50% {
    box-shadow: 0 0 50px 15px #e0a74875;
  }

  100% {
    box-shadow: 0 0 50px 1px #e0a74875;
  }
}

.glow-border {
  animation: shadows 1s infinite;
}

.space-outside-text-container {
  text-align: left;
}

.nep-song {
  position: relative;
  height: 1206px;
  margin: auto;
  padding-bottom: 120px;
}

.nep-song-heading {
  position: absolute;
  top: 38%;
  width: 100%;
  height: 10vh;
  text-align: center;
  z-index: 1;
  color: var(--bg-red);
}

.nep-song-text-container {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.nep-song-image-container {
  position: relative;
}

.blackboard {
  position: absolute;
  left: 0;
}

.choi-co {
  position: absolute;
  width: 35vw;
  top: 156px;
}

.nep-song-1 {
  position: absolute;
  width: 30vw;
  left: 0;
}

.nep-song-2 {
  position: absolute;
  width: 20vw;
  aspect-ratio: 1/1;
  left: 20%;
  top: 65%;
}

.nep-song-3 {
  position: absolute;
  width: 30vw;
  left: 65%;
  top: 60%;
}

.nep-song-1,
.nep-song-2,
.nep-song-3 {
  aspect-ratio: var(--image-mobile-vertical);
  object-fit: cover;
}

.space-nature-session {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 180px;
}

.zoom-scroll-image {
  height: 1000px;
}

.zoom-scroll-image::-webkit-scrollbar {
  display: none;
}

.space-nature-heading-container {
  height: 597px;
  display: flex;
  flex-shrink: 0;
  position: relative;
  justify-content: center;
  align-items: center;
}

.tree-back {
  width: 100%;
  top: -250px;
  position: absolute;
  object-fit: cover;
}

.tree-front {
  width: 100%;
  top: -270px;
  position: absolute;
  object-fit: cover;
}

.tree-left {
  width: 978px;
  height: 957px;
  left: -345px;
  position: absolute;
}

.tree-left-3 {
  mix-blend-mode: multiply;
}

.tree-right {
  width: 914px;
  height: 846px;
  position: absolute;
  right: 0;
  top: 21px;
  right: -242px;
}

.tree-right-4 {
  mix-blend-mode: multiply;
}

.space-natural-content-container {
  width: 100%;
  position: relative;
  padding-bottom: 100px;
}

.space-natural-text-container {
  text-align: left;
  color: var(--text-grey-h1);
  height: 100%;
}

.space-natural-text-container div {
  color: var(--text-grey);
}

.space-natural-content-card {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.space-natural-card-2 {
  top: -60px;
  align-items: center;
}

.space-natural-text-1 {
  position: relative;
  top: 90px;
}

.space-natural-text-2 {
  position: relative;
  /* bottom: 80px; */
}

.space-natural-image {
  width: 100%;
}

.space-natural-image-1 {
  width: 30vw;
}

.space-natural-image-2 {
  width: 30vw;
  bottom: 80px;
  position: relative;
}

.space-nature-text {
  max-width: 620px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  display: inline-flex;
  color: var(--text-grey-h1);
  z-index: 10;
}

.space-natural-text div {
  color: var(--text-grey);
}

.progress-bar {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 10px;
  background: var(--bg-red);
  transform-origin: 0%;
  z-index: 200;
}

.space-natural-main-image {
  width: 100%;
  aspect-ratio: 2320/1547;
  position: relative;
}

.main-image-container {
  position: sticky;
  top: 10%;
}

.space-nature-piece-transition {
  height: 100px;
}

.space-piece-session {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-beige);
}

.space-piece-heading {
  text-align: left;
}

.space-piece-heading-1 {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  text-align: left;
}

.space-piece-card {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.card-right {
  align-items: flex-end;
}

.space-piece-card-bodytext {
  text-align: justify;
  color: var(--ink60);
}

.space-piece-container-2 {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.space-image-1 {
  aspect-ratio: 1/1;
  position: absolute;
  left: 0;
  max-width: 350px;
}

.space-image-2 {
  position: absolute;
  right: 0;
  max-width: 1015px;
  aspect-ratio: 1600/836;
}

.space-piece-container-3 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.space-piece-container-4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.space-image-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  aspect-ratio: 5/3;
}

.space-image-5 {
  aspect-ratio: 1/1;
  position: absolute;
  bottom: 0;
  right: 0;
}

.space-reservation-session {
  width: 100vw;
  height: 540px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.space-reservation-text {
  width: 676px;
  height: 146px;
  flex-direction: column;
  gap: 40px;
  display: inline-flex;
  align-items: center;
}

.image-slide-session {
  width: 100%;
  position: relative;
  max-height: 800px;
  overflow: hidden;
  aspect-ratio: var(--image-horizontal);
}

.image-slide-session-mobile {
  width: 100%;
  position: relative;
  aspect-ratio: var(--image-horizontal);
}

@media only screen and (max-width: 600px) {
  .space-community-text-container {
    padding: 72px 24px;
  }

  .khu-tap-the-graphic {
    aspect-ratio: 1/1;
  }

  .stair {
    background-size: contain;
    top: 0;
  }

  .treo-quan-ao {
    top: -10px;
    height: 336px;
    object-fit: contain;
    width: 100%;
  }

  .space-outside {
    top: 9%;
  }

  .space-outside-mobile {
    padding: 0 24px;
  }

  .nep-song {
    margin: auto;
    height: 755px;
    padding: 0 24px 120px;
  }

  .nep-song-image-container {
    width: 100%;
    margin-top: 40px;
  }

  .nep-song-heading {
    top: 56%;
    max-width: 342px;
  }

  .nep-song-1 {
    top: 36%;
    left: -45px;
    width: 40vw;
  }

  .nep-song-2 {
    top: 75%;
    width: 30vw;
    left: 5%;
  }

  .nep-song-3 {
    top: 71%;
    width: 40vw;
    left: 54%;
  }

  .blackboard {
    width: 80%;
    left: 110px;
  }

  .choi-co {
    width: 70%;
    top: 71px;
    right: -57px;
  }

  .space-nature-text {
    gap: 0;
  }

  .space-nature-heading-container {
    height: 350px;
  }

  .space-natural-content-card {
    flex-direction: column-reverse;
    width: auto;
    gap: 12px;
  }

  .space-natural-content-container {
    display: flex;
    flex-direction: column;
    gap: 100px;
    width: 100%;
    padding: 0 40px;
  }

  .space-natural-main-image {
    left: -21px;
    top: 160px;
  }

  .main-image-container {
    height: 400px;
  }

  .space-nature-piece-transition {
    height: 700px;
  }

  .space-piece-heading-1,
  .space-piece-container-3 {
    flex-direction: column;
    gap: 24px;
  }

  .space-piece-container-2 {
    height: 160px;
  }

  .space-piece-container-4 {
    flex-direction: column-reverse;
    gap: 24px;
  }

  .space-piece-container-4 img {
    width: 100%;
  }

  .space-piece-card {
    gap: 0;
    text-align: left;
  }

  .space-nature-session {
    padding: 24px;
  }

  .space-piece-session {
    padding: 24px;
    gap: 24px;
  }

  .space-image-container {
    margin: 0 44px;
    aspect-ratio: var(--image-mobile-horizontal);
  }

  .space-image-1 {
    width: 24vw;
  }

  .space-image-2 {
    width: 70vw;
  }

  .space-image-4 {
    width: 50%;
  }

  .space-image-5 {
    width: 35%;
  }

  .space-piece-heading-2 {
    margin-top: 12px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .space-community-text-container {
    width: 70vw;
    padding: 80px 0;
  }

  .stair {
    background-size: contain;
  }

  .treo-quan-ao {
    width: 70vw;
    top: -10%;
    height: auto;
    object-fit: contain;
  }

  .space-outside {
    top: -4%;
    width: 40vw;
  }

  .nep-song {
    margin: 0 80px;
    height: 636px;
  }

  .nep-song-text-container {
    width: 35vw;
    left: 16px;
    top: 14px;
    position: absolute;
  }

  .nep-song-image-container {
    width: 50vw;
    left: 50%;
  }

  .nep-song-heading {
    left: 0;
  }

  .nep-song-1 {
    top: 33%;
  }

  .nep-song-2 {
    top: 74%;
  }

  .nep-song-3 {
    top: 42%;
  }

  .blackboard {
    width: 40vw;
  }

  .choi-co {
    top: 86px;
    left: 10%;
  }

  .tree-front,
  .tree-back {
    top: -150px;
  }

  .space-nature-heading-container {
    height: 400px;
  }

  .space-natural-text-container {
    width: 35vw;
  }

  .space-natural-text-1 {
    top: 30px;
  }

  .space-natural-image-1,
  .space-natural-image-2 {
    width: 35vw;
  }

  .space-natural-card-2 {
    top: 100px;
  }

  .space-natural-main-image {
    left: -45px;
    top: 160px;
  }

  .main-image-container {
    height: 700px;
  }

  .space-nature-piece-transition {
    height: 900px;
  }

  .space-piece-heading-1 {
    flex-direction: column;
  }

  .space-piece-container-2 {
    height: 250px;
  }

  .space-piece-card {
    text-align: left;
    width: 35vw;
  }

  .card-right {
    text-align: end;
  }

  .space-nature-session {
    padding: 0 80px;
  }

  .space-piece-session {
    padding: 80px;
  }

  .space-image-1 {
    width: 24vw;
  }

  .space-image-2 {
    width: 70vw;
  }

  .space-image-3 {
    width: 50%;
  }

  .space-image-4 {
    width: 46%;
  }

  .space-image-5 {
    width: 32%;
  }

  .space-piece-heading-2 {
    padding-top: 80px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .space-community-text-container {
    width: 50vw;
    padding-top: 110px;
  }

  .space-outside {
    width: 35vw;
  }

  .nep-song {
    margin: 0 80px;
    height: 773px;
  }

  .nep-song-text-container {
    width: 30vw;
    left: 80px;
    top: 76px;
    position: absolute;
  }

  .nep-song-1 {
    top: 37%;
  }

  .nep-song-2 {
    top: 85%;
  }

  .nep-song-3 {
    top: 60%;
  }

  .blackboard {
    width: 40vw;
  }

  .choi-co {
    top: 156px;
  }

  .space-nature-heading-container {
    height: 597px;
  }

  .space-natural-text-container {
    width: 33vw;
  }

  .space-natural-text-1 {
    top: 44px;
  }

  .space-natural-text-2 {
    /* bottom: 10px; */
  }

  .space-natural-image-1 {
    width: 33vw;
  }

  .space-natural-image-2 {
    width: 30vw;
    bottom: 10px;
  }

  .main-image-container {
    height: 1000px;
  }

  .space-nature-piece-transition {
    height: 1200px;
  }

  .space-piece-heading {
    max-width: 320px;
  }

  .space-piece-heading-1 {
    flex-direction: row;
    margin-bottom: 44px;
    margin-top: 37px;
  }

  .space-piece-heading-2 {
    width: 70%;
    padding-top: 160px;
    padding-bottom: 0;
    max-width: 800px;
  }

  .space-piece-container-2 {
    height: 360.26px;
  }

  .space-piece-container-4 {
    margin-top: 44px;
  }

  .space-image-container {
    width: 35vw;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .stair-2 {
    top: 272px;
  }

  .space-outside {
    width: 30vw;
  }

  .nep-song {
    height: 1006px;
  }

  .nep-song-1 {
    top: 26%;
  }

  .nep-song-2 {
    top: 60%;
  }

  .nep-song-3 {
    top: 45%;
  }

  .blackboard {
    width: 40vw;
  }

  .choi-co {
    top: 156px;
  }

  .tree-back {
    top: -250px;
  }

  .tree-front {
    top: -270px;
  }

  .space-nature-session {
    padding: 0 120px;
  }

  .space-natural-text-1 {
    top: 64px;
  }

  .space-natural-text-2 {
    /* bottom: 24px; */
  }

  .space-natural-main-image {
    left: -54px;
    top: 0;
  }

  .space-piece-session {
    padding: 120px;
  }

  .space-piece-heading {
    width: 30vw;
    margin-top: 12px;
  }

  .space-piece-card {
    text-align: left;
    width: 35vw;
  }

  .space-piece-container-2 {
    height: 445.26px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .stair {
    background-size: cover;
  }

  .treo-quan-ao {
    height: 498px;
    top: 3%;
    position: absolute;
    left: 0;
    object-fit: cover;
  }

  .space-outside {
    width: 30vw;
    top: 5%;
  }

  .nep-song {
    height: auto;
    aspect-ratio: 1440 / 1260;
  }

  .nep-song-1 {
    top: 350px;
  }

  .nep-song-2 {
    top: 70%;
  }

  .nep-song-3 {
    top: 50%;
  }

  .blackboard {
    width: 40vw;
  }

  .choi-co {
    top: 156px;
  }

  .space-nature-session {
    padding: 0 180px;
  }

  .space-nature-heading-container {
    height: 700px;
  }

  .space-natural-text-container {
    width: 30vw;
  }

  .space-natural-text-1 {
    top: 90px;
  }

  .space-natural-text-2 {
    /* bottom: 80px; */
  }

  .space-natural-image-1 {
    width: 30vw;
  }

  .space-natural-image-2 {
    bottom: 80px;
  }

  .space-natural-main-image {
    left: -77px;
  }

  .space-piece-session {
    padding: 180px 180px 80px;
  }

  .space-piece-heading {
    width: 30vw;
  }

  .space-piece-card {
    text-align: left;
    width: 30vw;
  }

  .space-piece-container-2 {
    height: 545px;
    margin-bottom: 44px;
  }
}
