.history-timeline-wrapper {
  position: relative;
  text-align: center;
  display: block;
  height: 80px;
}

.history-line {
  display: block;
  width: 95%;
  height: 1px;
  background: var(--line-grey);
  position: absolute;
  top: 50%;
}

.history-button-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.history-button {
  display: flex;
  justify-content: center;
  width: 10%;
}

.history-button-start {
  justify-content: flex-start;
}

.history-dot {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
}

.history-dot-default {
  width: 7px;
  height: 7px;
  background-color: var(--ink60);
  top: 46%;
  transition: all 0.2s ease;
}

.history-dot-active {
  width: 12px;
  height: 12px;
  background-color: var(--active-red);
  top: 44%;
  transition: all 0.2s ease;
}

.history-image-container {
  overflow: hidden;
  aspect-ratio: var(--image-vertical);
  object-fit: cover;
}

.history-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease-in-out;
}

.history-image-container:hover .history-image {
  transform: scale(1.1);
  transition: all 0.5s ease-in-out;
}

.history-details-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  padding-bottom: 50px;
}

.history-container {
  display: flex;
  flex-direction: column;
  gap: 56px;
  max-width: 800px;
}

.history-text-container {
  overflow: scroll;
}

.history-text-container::-webkit-scrollbar {
  display: none;
}

.history-text .body-b1 {
  color: var(--ink60);
}

.history-content {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .history-content {
    flex-direction: column;
  }

  .history-details-text {
    padding-bottom: 40px;
  }

  .history-image-container {
    width: 100vw;
    aspect-ratio: 1/1;
  }

  .history-container {
    padding: 44px 24px;
    gap: 32px;
  }

  .history-text-container {
    height: 420px;
  }

  .history-timeline-wrapper {
    overflow: scroll;
    margin: 0 auto;
    width: 85%;
  }

  .history-timeline-wrapper::-webkit-scrollbar {
    display: none;
  }

  .history-line {
    width: 120%;
  }

  .history-button-container {
    justify-content: flex-start;
  }

  .history-button {
    width: 25%;
  }

  .history-button-start {
    width: 15%;
  }

  .history-text-button-container {
    gap: 40px;
  }
}

@media only screen and (min-width: 600px) {
  .history-content {
    flex-direction: column;
  }

  .history-text-button-container {
    gap: 40px;
  }

  .history-image-container {
    width: 100vw;
    aspect-ratio: 1/1;
  }

  .history-container {
    padding: 44px 24px;
    gap: 16px;
  }

  .history-text-container {
    height: 500px;
  }

  .history-timeline-wrapper {
    margin: 0 40px;
    width: 85%;
  }
}

@media only screen and (min-width: 769px) {
  .history-content {
    flex-direction: row;
  }

  .text-button-container {
    gap: 40px;
  }

  .history-text-button-container {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    gap: 40px;
  }

  .history-image-container {
    width: 50vw;
    aspect-ratio: var(--image-vertical);
  }

  .history-container {
    gap: 16px;
    padding: 64px 40px 24px;
  }

  .history-timeline-wrapper {
    margin: 0 40px;
    width: 75%;
  }

  .history-details-text {
    width: 50vw;
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 992px) {
  .text-button-container {
    gap: 108px;
  }

  .history-container {
    padding: 100px 80px 24px;
    gap: 56px;
  }

  .history-text-button-container {
    gap: 80px;
  }

  .history-timeline-wrapper {
    margin: 0 80px;
    width: 70%;
  }

  .history-text-container {
    height: 600px;
  }
}

@media only screen and (min-width: 1200px) {
  .history-details-text {
    padding-bottom: 50px;
  }
}
