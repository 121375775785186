.landing-session {
  display: flex;
  justify-content: center;
  margin: 0;
  width: 100%;
  aspect-ratio: var(--image-horizontal);
  object-fit: cover;
}

.landing-image {
  width: 100%;
  aspect-ratio: var(--image-horizontal);
  position: absolute;
  z-index: -1;
  object-fit: cover;
}

.landing-session img,
.landing-image img {
  object-fit: cover;
}

.landing-text {
  max-width: 818px;
  width: 60vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  padding: 100px 0;
}

.sticky-container {
  background-color: white;
  position: sticky;
  z-index: 12;
  width: 40px;
  height: 0;
  top: 50%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 32px;
}

.mobile-sticky-container {
  position: fixed;
  z-index: 12;
  width: 40px;
  bottom: 16px;
  right: 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
}

.cta-button-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.cta-toggle-button {
  width: 48px;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.25);
  color: white;
  border-radius: 50%;
  transition: all 0.5s ease;
}

.rotate-button.close-rotate {
  transform: rotate(45deg);
  transition: transform 0.2s ease;
}

.rotate-button.add-rotate {
  transform: rotate(0);
  transition: transform 0.2s ease;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .landing-session,
  .landing-image {
    aspect-ratio: var(--image-mobile-vertical);
    overflow: hidden;
  }

  .landing-text {
    width: 100%;
    padding: 0 24px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) ipad air */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
