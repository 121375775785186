.home-philosophy-session {
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
  padding: 100px 0;
  background-image: url("../../public/assets/General/RedBackground2.png");
}

.hand-holding-chopstick-image {
  min-width: 190px;
  max-width: 224px;
}

.home-philosophy-text {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  display: flex;
}

.home-moi-com-session {
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
  background-color: var(--bg-beige);
}

.mam-com-image {
  width: 810px;
  height: 586px;
  left: 189px;
  top: 0;
}

.home-moi-com-slide {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 80px;
}

.home-blog-list-container {
  padding: 100px 0;
  background-color: var(--bg-beige);
}

.com-ngon-heading {
  margin-bottom: 80px;
}

.home-press-review-session {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 44px;
  margin: 80px 0;
}

.home-press-review-logo {
  max-width: 167px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .home-philosophy-session {
    padding: 64px 0;
  }

  .home-philosophy-text {
    gap: 24px;
    padding: 24px;
    max-width: 318px;
  }

  .hand-holding-chopstick-image {
    min-width: 190px;
    width: 25vw;
  }

  .home-moi-com-session {
    padding: 72px 0;
  }

  .home-moi-com-slide {
    margin: 0 40px;
  }

  .home-blog-list-container {
    padding: 64px 0;
  }

  .com-ngon-heading {
    margin: 0 auto;
    max-width: 256px;
  }

  .home-press-review-session {
    margin: 64px 0;
    gap: 0;
  }

  .home-press-review-logo {
    max-width: 167px;
    width: 30vw;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .home-philosophy-session {
    padding: 40px 0;
  }

  .hand-holding-chopstick-image {
    max-width: 160px;
  }

  .home-press-review-logo {
    height: 64px;
    width: 15vw;
    max-width: 167px;
  }

  .com-ngon-heading {
    margin-left: 80px;
    margin-right: 80px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .home-philosophy-session {
    padding: 56px 0;
  }

  .hand-holding-chopstick-image {
    max-width: 190px;
  }
}

/* Large devices (laptops/desktops, 992px and up) ipad air */
@media only screen and (min-width: 992px) {
  .home-philosophy-session {
    padding: 100px 0;
  }

  .hand-holding-chopstick-image {
    max-width: 222.08px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
