.contact-container {
  margin: 120px auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-container a {
  text-decoration: underline;
  color: var(--murrey);
}

@media only screen and (max-width: 600px) {
  .contact-container {
    padding: 0 24px;
    margin: 64px auto;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .contact-container {
    width: 70vw;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) ipad air */
@media only screen and (min-width: 992px) {
  .contact-container {
    width: 50vw;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
