.blog-slide-container {
  width: 100vw;
  display: flex;
  height: 100%;
}

.blog-slide-background {
  width: 100%;
  object-fit: cover;
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
}

.blog-slide-text-button {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.blog-slide-grid-container {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.blog-slide-grid {
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.blog-slide-content-grid {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog-slide-content {
  display: flex;
  max-width: 720px;
  overflow: hidden;
  align-items: center;
}

.blog-slide-content-text {
  width: 100%;
  object-fit: cover;
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
}

.blog-slide-heading {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.blog-slide-heading:hover {
  text-decoration: underline;
  text-underline-offset: 16px;
}

.blog-swipe-heading {
  position: absolute;
  top: 35%;
  width: 100%;
}

.blog-swipe-heading p {
  margin: 0 24px;
}

.blog-slide-dots-container {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog-slide-dots-container-mobile {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  bottom: 5%;
}

.slide-dots-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
