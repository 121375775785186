.map-controller-container {
  display: inline-flex;
  align-items: flex-start;
  flex-direction: row-reverse;
  margin: auto;
  justify-content: center;
  position: relative;
}

.map {
  width: 30vw;
  height: auto;
  overflow: visible;
}

.ingredient-controller {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ingredient-controller-horizontal {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.ingredient-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  aspect-ratio: 1/1;
  border: var(--text-grey-body) 1px solid;
  border-radius: 50%;
  overflow: hidden;
}

.ingredient-avatar-image {
  object-fit: cover;
  width: 110%;
  background-color: white;
}

.ingredient-avatar:hover {
  border: var(--bg-red) 1px solid;
}

.ingredient-avatar-image-invisible {
  object-fit: cover;
  width: 56px;
  aspect-ratio: 1/1;
}

.map-heading {
  position: absolute;
  width: 70%;
  left: -50px;
  top: 20%;
  text-align: left;
  color: var(--bg-red);
}

.hs-ts {
  position: absolute;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .map-heading {
    width: 100%;
    top: 0;
    left: -35px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 769px) {
  .map-heading {
    width: 88%;
    left: -50px;
    top: 20%;
    max-width: 210px;
  }
}

/* Large devices (laptops/desktops, 992px and up) ipad air */
@media only screen and (min-width: 992px) {
  .map-heading {
    width: 80%;
    top: 20%;
    max-width: 230px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .map-heading {
    width: 70%;
    top: 43%;
  }
}
