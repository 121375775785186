/* blog listing component */
.blog-list {
  overflow: scroll;
  cursor: grab;
}

.blog-list::-webkit-scrollbar {
  display: none;
}

.blog-card-list {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 44px;
  margin-bottom: 24px;
}

.blog-card {
  flex-direction: column;
  gap: 16px;
  display: inline-flex;
  width: 100%;
  cursor: pointer;
}

.blog-card-image-container {
  overflow: hidden;
  aspect-ratio: 3/2;
}

.blog-card-image-container .zoom {
  transition: all 0.5s ease-in-out;
}

.blog-card-image {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: cover;
}

.blog-card-text {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  display: flex;
}

.blog-card-text-heading {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.2;
}

.blog-card-text-heading p {
  margin: 0;
}

.blog-card-title {
  align-self: stretch;
  font-size: 18px;
  font-family: "League Spartan";
  font-weight: 400;
  overflow-wrap: break-word;
}

.blog-card-subtitle {
  font-size: 16px;
  font-family: "League Spartan";
  font-weight: 400;
  color: var(--text-subtitle);
  overflow-wrap: break-word;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  text-align: left;
  line-height: 1.2;
}

.blog-card-category {
  display: flex;
  align-items: center;
  gap: 4px;
}

.blog-card-category-container {
  display: flex;
  gap: 12px;
}

.max-width-167 {
  max-width: 167px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .blog-card-text-heading {
    font-weight: 400;
    line-height: 24px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .blog-card-list {
    margin-left: 40px;
  }
}

/* Large devices (laptops/desktops, 992px and up) ipad air */
@media only screen and (min-width: 992px) {
  .blog-card-list {
    margin-left: 80px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
