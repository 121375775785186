.spice-container {
  position: relative;
  width: 100%;
}

.spice-content {
  gap: 120px;
  justify-content: flex-end;
}

.spice-details-image {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  object-fit: cover;
  width: 100%;
}

.spice-image-center {
  object-position: center;
}

.spice-image-bottom {
  object-position: bottom;
}

.spice-details-text {
  padding: 0 0 40px;
  text-align: left;
  width: 35vw;
}

.spice-details-text::-webkit-scrollbar {
  display: none;
}

.spice-details-text .body-b1 {
  color: var(--text-grey-body);
}

.spice-controller {
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 50px;
  color: white;
  max-width: 636px;
}

/* .spice-controller-group {
  display: flex;
  flex-wrap: wrap;
} */

.spice-controller-text {
  display: flex;
  flex-wrap: wrap;
}

.spice-image-container {
  overflow: hidden;
  max-height: 600px;
}

.spice-image-container .spice-details-image {
  transition: all 0.5s ease-in-out;
}

.line-height-12 {
  line-height: 12px;
}

@media only screen and (max-width: 600px) {
  .spice-content {
    gap: 32px;
  }

  .spice-details-image {
    width: 100%;
    aspect-ratio: var(--image-mobile-horizontal);
    overflow: hidden;
  }

  .spice-details-text {
    width: 100%;
  }

  .spice-controller {
    width: 100%;
    gap: 32px;
  }

  .spice-controller-text {
    line-height: 40px;
  }
}

@media only screen and (min-width: 600px) {
  .spice-controller {
    width: 100%;
    gap: 32px;
    top: 32px;
  }

  .spice-content {
    gap: 40px;
  }

  .spice-details-image {
    width: 100%;
    aspect-ratio: var(--image-mobile-horizontal);
    overflow: hidden;
  }

  .spice-details-text {
    width: 100%;
    margin-top: 32px;
  }
}

@media only screen and (min-width: 768px) {
  .spice-details-image {
    aspect-ratio: var(--image-vertical);
  }

  .spice-details-text {
    max-width: 512px;
    height: 500px;
    overflow: scroll;
    justify-content: flex-start;
    width: 35vw;
  }

  .spice-controller {
    width: 60%;
    gap: 24px;
    top: 32px;
    position: absolute;
  }

  .spice-image-container {
    max-width: 432px;
    width: 30vw;
  }
}

@media only screen and (min-width: 992px) {
  .spice-controller {
    width: 45vw;
    top: 46px;
    gap: 50px;
  }

  .spice-content {
    gap: 80px;
  }

  .spice-details-text {
    height: auto;
    margin-top: 46px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .spice-content {
    gap: 120px;
  }
}
