/* address session component */

.address-session {
  background: var(--bg-red);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.address-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 40px auto;
  max-width: 1052px;
}

.house-image {
  max-width: 245px;
  width: 40%;
  aspect-ratio: 1/1;
}

.address-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.address-text {
  margin-top: 24px;
  margin-bottom: 44px;
  font-size: 24px;
  color: white;
  font-family: "League Spartan";
  font-weight: 400;
  text-align: left;
  line-height: 32px;
}
.map-link {
  left: 0px;
  top: 106px;
  position: relative;
  font-size: 15px;
  color: white;
  font-family: "League Spartan";
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.75px;
}

.google-map {
  width: 80vw;
  position: relative;
  overflow: hidden;
}
.google-map iframe {
  padding-bottom: 120px;
  border: 0;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .house-image {
    max-width: 200px;
  }

  .address-container {
    width: 90vw;
    gap: 24px;
    flex-direction: column;
  }

  .address-text-container {
    text-align: center;
    align-items: center;
  }

  .address-text {
    margin: 24px 0;
    text-align: center;
    max-width: 280px;
  }

  .google-map {
    width: 100vw;
  }

  .google-map iframe {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 600px) {
  .address-session-mobile {
    position: relative;
    top: -5px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .address-container {
    width: 80vw;
    gap: 40px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .house-image {
    max-width: 200px;
  }

  .address-container {
    width: 80vw;
    gap: 40px;
  }

  .cta-container {
    padding-left: 100px;
  }
}

/* Large devices (laptops/desktops, 992px and up) ipad air */
@media only screen and (min-width: 992px) {
  .house-image {
    max-width: 245px;
  }

  .address-container {
    gap: 100px;
    padding-left: 100px;
  }

  .cta-container {
    padding-left: 160px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .address-container {
    padding-left: 160px;
  }
}
