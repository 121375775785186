/* stylelint-disable font-family-no-missing-generic-family-keyword */
/* stylelint-disable font-family-name-quotes */
:root {
  --ink90: #1e1e1e;
  --ink60: #717171;
  --bg-beige: #fffaf3;
  --bg-red: #660522;
  --bg-blue: #001624;
  --line-grey: #d9d9d9;
  --disable: #ccc;
  --murrey: #8c062e;
  --active-red: #9a1023;
  --text-grey-h1: #e5e7e9;
  --text-grey-body: #b2b9bd;
  --text-subtitle: #848484;
  --text-heading-red: #d04233;
  --image-horizontal: 1440/900;
  --image-vertical: 720/900;
  --image-banner: 1440/580;
  --image-mobile-vertical: 390/540;
  --image-mobile-horizontal: 344/232;
  transition: all 0.2s ease-in-out;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
  position: relative;
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header-text {
  /* Header R64 */
  font-family: "League Spartan";
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 72px; /* 112.5% */
}

.header-text-accent {
  font-family: "Akbar";
}

/* .header-text-accent:hover {
  text-decoration-line: underline;
  text-decoration-thickness: 0.5px;
  text-underline-offset: 8px;
  transition: text-decoration-line 1s ease;
  -webkit-transition: text-decoration-line 0.2s ease;
} */

.underline-animate {
  display: inline-block;
  padding-bottom: 3px;
}

.underline-animate::after {
  content: "";
  display: block;
  height: 2px;
  background-color: white;
  animation: underline 1s infinite;
}

@keyframes underline {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

h1 {
  /* Title H1 36 */
  font-family: "League Spartan";
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  font-size: 32px;
}

h2 {
  /* Title H2 32 */
  font-family: "League Spartan";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px; /* 137.5% */
}

h3 {
  /* Title H3 R26 */
  font-family: "League Spartan";
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 123.077% */
}

.bold {
  font-weight: 500;
}

.regular-heading-text {
  font-size: 36px;
  font-family: "League Spartan";
  font-weight: 400;
  line-height: 44px;
  word-wrap: break-word;
}

.accent-heading-text {
  font-size: 36px;
  font-family: "Akbar";
  font-weight: 400;
  line-height: 44px;
  word-wrap: break-word;
}

.body-b1 {
  /* Body B1 L20 */
  font-family: "League Spartan";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px; /* 150% */
}

.body-b2 {
  /* Body B2 R18 */
  font-family: "League Spartan";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}

.cap-r15 {
  /* CAP R15 */
  font-family: "League Spartan";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.75px;
  text-transform: uppercase;
}

.cap-m15 {
  /* CAP M15 */
  font-family: "League Spartan";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.75px;
  text-transform: uppercase;
}

br {
  display: block;
  margin-top: 16px;
  content: "";
}

.background-image {
  position: absolute;
  z-index: -1;
  object-fit: cover;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  left: 0;
}

.custom-button-navbar {
  font-size: 16px;
  font-family: "League Spartan";
  font-weight: 400;
  word-wrap: break-word;
  padding: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.custom-button-navbar::after {
  content: "";
  display: block;
  padding-top: 3px;
  border-bottom: 2px solid transparent;
  margin: auto;
  width: 20px;
}

.black,
.black-hover {
  color: var(--ink90);
}

.black-hover:hover {
  color: var(--murrey);
}

.white,
.white-active {
  color: white;
}

.white-active::after {
  border-bottom: 2px solid white;
}

.murrey {
  color: var(--murrey);
}

.murrey::after {
  border-bottom: 2px solid var(--murrey);
}

.custom-button-link {
  font-family: "League Spartan";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: block;
  position: relative;
  padding: 0.3em 0;
  overflow: hidden;
}

/* Slide in */
.custom-button-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: white;
}

.custom-button-link::after {
  transform: scaleX(1);
  transform-origin: bottom left;
  transition: transform 0.5s ease-out;
}

.custom-button-link:hover::after {
  transform: scaleX(0);
  transform-origin: bottom right;
}

.custom-button-with-icon {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  font-family: "League Spartan";
  font-size: 15px;
  padding: 20px 0 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  white-space: nowrap;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.custom-icon-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.custom-button-language {
  font-family: "League Spartan";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  word-wrap: break-word;
  padding: 15px 0 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.custom-button-language-disabled {
  font-family: "League Spartan";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  word-wrap: break-word;
  padding: 15px 0 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--disable);
}

.custom-button-language-drawer {
  padding: 0;
  color: white;
}

.custom-button-language-drawer-disable {
  color: rgba(255, 255, 255, 0.5);
}

.custom-button-slide {
  font-size: 14px;
  font-family: "League Spartan";
  text-transform: uppercase;
  letter-spacing: 0.7px;
  word-wrap: break-word;
  background-color: transparent;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  padding: 10px 0;
}

.close-button {
  position: absolute;
  z-index: 11;
}

a {
  text-decoration: none;
  color: var(--ink90);
}

.clickable {
  cursor: pointer;
}

.non-clickable {
  cursor: default;
}

.display-none {
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.display-block {
  opacity: 1;
  transition: all 0.5s ease-in-out;
  z-index: 5;
}

.zoom:hover {
  transform: scale(1.05);
  transition: all 0.5s ease-in-out;
}

.lazy-image {
  width: 100%;
  overflow-y: hidden;
}

.mobile-button-container {
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
}

.mobile-button-container-slide {
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
}

.facebook-icon:hover,
.instagram-icon:hover {
  fill: var(--murrey);
}

@media only screen and (max-width: 600px) {
  h1,
  .regular-heading-text,
  .accent-heading-text {
    /* Title H1 36 */
    font-size: 32px;
    line-height: 36px;
  }

  h2 {
    /* Title H2 32 */
    font-size: 28px;
    line-height: 40px;
  }

  h3 {
    /* Title H3 32 */
    font-size: 24px;
    line-height: 38px;
  }

  .header-text {
    font-size: 44px;
    line-height: 52px;
  }

  .body-b2 {
    /* Body B2 R18 */
    font-weight: 300;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  h1 {
    /* Title H1 36 */
    font-size: 32px;
  }

  h2 {
    /* Title H2 32 */
    font-size: 28px;
    line-height: 40px;
  }

  .regular-heading-text {
    font-size: 32px;
  }

  .accent-heading-text {
    font-size: 28px;
  }

  .header-text {
    font-size: 56px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  h1 {
    /* Title H1 36 */
    font-size: 32px;
  }

  h2 {
    /* Title H2 32 */
    font-size: 28px;
  }

  .regular-heading-text {
    font-size: 32px;
  }

  .accent-heading-text {
    font-size: 32px;
  }

  .header-text {
    font-size: 56px;
  }
}

@media only screen and (min-width: 769px) {
  html {
    overflow-x: visible;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  body {
    overflow-x: hidden;
  }

  h1 {
    /* Title H1 36 */
    font-size: 36px;
  }

  h2 {
    /* Title H2 32 */
    font-size: 32px;
  }

  .regular-heading-text {
    font-size: 36px;
  }

  .accent-heading-text {
    font-size: 36px;
  }

  .header-text {
    font-size: 64px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
