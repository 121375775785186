.home-ingredient-session {
  width: 100vw;
  background: var(--bg-blue);
  display: flex;
}

.home-ingredient-wrapper {
  position: absolute;
  overflow: hidden;
}

.home-ingredient-image {
  height: 100%;
  width: 100vw;
  top: 0;
  position: relative;
}

.layer01 {
  z-index: 1;
  position: absolute;
}

.layer02 {
  z-index: 2;
  position: absolute;
}

.home-ingredient-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  margin: auto;
}

.home-ingredient-text {
  max-width: 520px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  display: inline-flex;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .home-ingredient-session,
  .home-ingredient-wrapper {
    height: 780px;
  }

  .home-ingredient-text {
    max-width: 300px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .home-ingredient-session,
  .home-ingredient-wrapper {
    height: 1400px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 769px) {
  .home-ingredient-session,
  .home-ingredient-wrapper {
    height: 1100px;
  }
}

/* Large devices (laptops/desktops, 992px and up) ipad air */
@media only screen and (min-width: 992px) {
  .home-ingredient-session,
  .home-ingredient-wrapper {
    height: 1150.219px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .home-ingredient-session,
  .home-ingredient-wrapper {
    height: auto;
    aspect-ratio: 1440/1266;
  }
}
