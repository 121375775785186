/* ingredient list component */
.ingredient {
  height: 700px;
  position: relative;
}

.ingredient-collapse-container {
  overflow: scroll;
  height: 800px;
  position: relative;
}

.ingredient-collapse-container::-webkit-scrollbar {
  display: none;
}

.ingredient-button {
  z-index: 11;
  position: absolute;
  left: 80px;
  top: 60px;
  display: flex;
  gap: 4px;
}

.ingredient-button-underline {
  color: var(--ink60);
}

.ingredient-button::after {
  left: 8px;
  bottom: 8px;
  background-color: var(--ink60);
}

.ingredient-mobile {
  aspect-ratio: var(--image-mobile-vertical);
  position: relative;
}

.ingredient-image-container {
  height: 100%;
  position: sticky;
  top: 0;
  z-index: 0;
}

.ingredient-text-container {
  position: absolute;
  height: 72%;
  overflow: scroll;
}

.ingredient-text-container-full {
  position: absolute;
  top: 80px;
  height: 81%;
  overflow: scroll;
}

.ingredient-text-container-mobile {
  overflow: scroll;
  aspect-ratio: var(--image-mobile-vertical);
  margin: 40px 0;
}

.ingredient-text-container::-webkit-scrollbar,
.ingredient-text-container-full::-webkit-scrollbar,
.ingredient-text-container-mobile::-webkit-scrollbar {
  display: none;
}

.ingredient-content-mobile {
  position: relative;
}

.ingredient-title-mobile,
.ingredient-title {
  position: absolute;
  color: white;
  z-index: 10;
  top: 40%;
  width: 100%;
}

.ingredient-title-button {
  font-size: 26px;
  font-weight: 400;
  margin: auto;
  text-transform: capitalize;
}

.ingredient-title-button-mobile {
  font-size: 22px;
  font-weight: 400;
  margin: auto;
  text-transform: capitalize;
}

.ingredient-preview-mobile {
  width: 100%;
  object-fit: cover;
}

.ingredient-list-container {
  overflow: visible;
}

.ingredient-list {
  align-items: center;
  display: inline-flex;
  overflow-y: hidden;
}

.ingredient-text-list {
  align-items: center;
  display: inline-flex;
  overflow-y: hidden;
}

.ingredient-image {
  position: absolute;
  top: 0;
  object-fit: cover;
  overflow: hidden;
}

.ingredient-image-mobile {
  object-fit: cover;
  overflow: hidden;
}

.ingredient-image .ingredient-lazy-image,
.ingredient-image-mobile .ingredient-lazy-image {
  transition: all 0.5s ease-in-out;
}

.ingredient-image-invisible {
  position: absolute;
  top: 0;
  object-fit: cover;
  width: 50%;
  height: 100%;
}

.ingredient-lazy-image {
  width: 100%;
  height: 100%;
}

.ingredient-image img,
.ingredient-image-mobile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ingredient-map-graphic {
  width: 50vw;
}

.map-container {
  width: 80%;
  padding: 40px;
  margin: auto;
  height: 80vh;
  display: flex;
  align-items: center;
}

.ingredient-details-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
}

.ingredient-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 56px;
  width: 100%;
}

.ingredient-card-text {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.ingredient-text-heading {
  align-self: stretch;
  color: var(--ink90);
  text-align: left;
}

.ingredient-text-body {
  align-self: stretch;
  color: var(--ink60);
  text-align: left;
}

.mobile-map-grid-container {
  background-image: url("../../assets/Map/island.svg");
  background-repeat: no-repeat;
}

.mobile-map-container {
  display: flex;
  flex-direction: column-reverse;
  gap: 200px;
}

.map-marker-text {
  transform: scale(4);
}

.other-ingredient-controller {
  position: absolute;
  left: 50%;
  top: 86%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 10;
  display: flex;
  flex-direction: row;
}

.other-ingredient-controller-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  z-index: 10;
  position: sticky;
  bottom: 0;
  background-color: white;
  border-top: 1px solid var(--line-grey);
  padding: 12px;
}

.ingredient-controller-label,
.ingredient-controller-label-mobile {
  display: flex;
  align-items: center;
  color: var(--ink60);
}

.ingredient-controller-button {
  display: flex;
  align-items: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .mobile-map-container {
    gap: 250px;
  }

  .ingredient-details-text {
    padding: 40px 0;
  }

  .ingredient-container {
    gap: 16px;
  }

  .ingredient-card {
    padding: 0 24px;
  }

  .ingredient-details-card {
    margin: 60px 24px;
  }

  .ingredient-image-mobile {
    width: 100%;
  }

  .mobile-map-grid-container {
    background-size: 75%;
    background-position: bottom 8% right -122%;
  }

  .map-marker-text {
    transform: scale(4);
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .ingredient {
    height: 600px;
  }

  .ingredient-image {
    width: 50%;
    height: 100%;
  }

  .ingredient-card {
    margin-left: 16px;
    margin-right: 80px;
    height: 400px;
  }

  .ingredient-details-card {
    margin: 60px 24px;
  }

  .mobile-map-grid-container {
    background-size: 30%;
    background-position: right 17% bottom 28%;
  }

  .ingredient-text-container {
    top: 40px;
  }

  .map-marker-text {
    transform: scale(3);
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 769px) {
  .ingredient {
    height: 600px;
  }

  .sticky-back-button {
    left: 32px;
    top: 28px;
  }

  .mobile-map-grid-container {
    background-size: 33%;
    background-position: right 50% bottom 41%;
  }

  .ingredient-card {
    height: 700px;
    padding-top: 100px;
  }

  .ingredient-details-card {
    margin-left: 16px;
    margin-right: 40px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .other-ingredient-controller {
    margin-left: 10px;
  }

  .ingredient-text-container {
    top: 40px;
  }

  .map-marker-text {
    transform: scale(2);
  }
}

/* Large devices (laptops/desktops, 992px and up) ipad air */
@media only screen and (min-width: 992px) {
  .ingredient {
    height: 700px;
  }

  .ingredient-card,
  .ingredient-details-card {
    margin-left: 40px;
    margin-right: 80px;
  }

  .other-ingredient-controller {
    margin-left: 32px;
  }

  .mobile-map-grid-container {
    background-size: 30%;
    background-position: right 52% bottom 40%;
  }

  .ingredient-text-container {
    top: 80px;
  }

  .map-marker-text {
    transform: scale(2);
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .ingredient {
    height: 900px;
  }

  .sticky-back-button {
    left: 80px;
    top: 60px;
  }

  .ingredient-card,
  .ingredient-details-card {
    margin: 0 120px;
  }

  .other-ingredient-controller {
    margin-left: 114px;
  }

  .mobile-map-grid-container {
    background-size: 20%;
    background-position: right 62% bottom 34%;
  }
}
