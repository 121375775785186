.press-review-content-container {
  height: 200px;
}

.press-review-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.press-review-content p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.swipe-review-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.swipe-press-review-content {
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.review-text {
  padding: 0 24px;
}

.press-review-logo-container {
  max-width: 620px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .press-review-content-container {
    padding: 0 24px;
    height: 240px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .press-review-content-container {
    padding: 0;
    width: 65vw;
  }
}

/* Large devices (laptops/desktops, 992px and up) ipad air */
@media only screen and (min-width: 992px) {
  .press-review-content-container {
    padding: 0;
    width: 60vw;
    max-width: 836px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
