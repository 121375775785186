.reservation-session {
  width: 100vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}

.reservation-container {
  flex-direction: column;
  gap: 16px;
  display: inline-flex;
  align-items: center;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .reservation-session {
    aspect-ratio: 1/1;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .reservation-session {
    height: 300px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .reservation-session {
    height: 400px;
  }
}

/* Large devices (laptops/desktops, 992px and up) ipad air */
@media only screen and (min-width: 992px) {
  .reservation-session {
    height: 540px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
