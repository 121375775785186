.blog-image-preview {
  width: 100%;
  aspect-ratio: var(--image-banner);
  object-fit: cover;
}

.blog-details-heading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog-details-heading p {
  margin: 0;
}

.blog-details-subtitle {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  padding: 0 40px;
  justify-content: center;
}

.blog-details-heading-container .body-b2 {
  color: var(--text-subtitle);
}
.blog-details-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
  font-family: "League Spartan";
  margin: 0 auto;
}
.content-paragraph {
  width: 100%;
  text-align: justify;
}
.content-paragraph figure {
  margin: 0;
}

.content-paragraph figure img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.full-image {
  width: 100vw;
}

.full-image figure img {
  aspect-ratio: var(--image-horizontal);
  object-fit: cover;
}

.content-paragraph iframe {
  width: 100%;
}

.content-paragraph .wp-block-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.content-paragraph .wp-block-gallery figure {
  width: 49%;
  aspect-ratio: 3/2;
}

.content-paragraph .wp-block-gallery img {
  object-fit: cover;
}

.blog-details-divider-container {
  display: flex;
  justify-content: center;
  padding: 0 40px;
}

.blog-details-divider {
  width: 100%;
}

.social-share-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 32px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .blog-details-heading-container {
    margin-top: 44px;
  }

  .blog-details-heading-container h1,
  .blog-details-content-container {
    padding: 0 40px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .blog-details-heading-container {
    margin: 64px 0;
  }

  .blog-details-heading-container h1,
  .blog-details-content-container,
  .blog-details-divider {
    width: 70vw;
  }

  .blog-details-divider-container {
    margin-top: 48px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) ipad air */
@media only screen and (min-width: 992px) {
  .blog-details-heading-container h1,
  .blog-details-content-container,
  .blog-details-divider {
    width: 50vw;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
