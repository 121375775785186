/* stylelint-disable font-family-no-missing-generic-family-keyword */
/* stylelint-disable font-family-name-quotes */
.footer {
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  display: inline-flex;
}

.mobile-footer-container {
}

.footer-grid-container {
  margin: 54px 80px 21px;
}

.mobile-footer-grid-container {
  display: flex;
  justify-content: space-between;
}

.footer-grid {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-bottom: 32px;
}

.footer-logo-grid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-logo-container {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.footer-main-content-grid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 42px;
}

.main-content-container {
  width: 100vw;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
}

.xoi-com-footer-logo {
  max-width: 233px;
  width: 100%;
}

.xoi-com-footer-logo-mobile {
  max-width: 162px;
  width: 100%;
}

.info {
  display: inline-flex;
  gap: 42px;
  padding: 0 24px 0 0;
  justify-content: flex-end;
}

.address {
  color: var(--ink90);
  font-size: 16px;
  font-family: "League Spartan";
  font-weight: 400;
  line-height: 32px;
  text-align: left;
}

.footer-contact-grid {
  display: flex;
}

.contact-us {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
}

.contact-us-label {
  color: var(--ink90);
  font-size: 16px;
  font-family: "League Spartan";
  font-weight: 400;
  line-height: 32px;
  text-align: left;
}

.contact-us-logo {
  gap: 14px;
  display: inline-flex;
}

.credit-container {
  padding: 0 0 0 100px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 136px;
  display: inline-flex;
}

.xoi-com-credit-text {
  color: var(--ink60);
  font-size: 16px;
  font-family: "League Spartan";
  font-weight: 400;
  word-wrap: break-word;
  text-align: left;
  margin-top: 14px;
}

.xoi-com-credit-link {
  color: var(--ink60);
  text-decoration: underline;
}

.divider {
  position: absolute;
  bottom: 36px;
  width: 100%;
}

.contact-footer-container {
  display: flex;
  width: 100vw;
  flex-direction: row;
}

.footer-card {
  width: 100%;
  aspect-ratio: var(--image-horizontal);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
  position: relative;
}

.footer-card .footer-image {
  transition: all 0.5s ease-in-out;
}

.footer-card:hover .footer-image {
  transform: scale(1.05);
  transition: all 0.5s ease-in-out;
}

.footer-image {
  position: absolute;
  z-index: -1;
  object-fit: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  left: 0;
}

.footer-card-text {
  font-size: 22px;
  padding: 0 60px;
  font-weight: 400;
  max-width: 360px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .footer-grid {
    flex-direction: column;
    align-items: center;
    margin: 40px auto;
    gap: 24px;
  }

  .contact-footer-container {
    flex-direction: column;
  }

  .contact-footer-mb {
    position: relative;
    top: -5px;
  }

  .contact-us {
    align-items: center;
  }

  .address {
    text-align: center;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .footer-grid {
    flex-direction: row;
  }

  .footer-logo-grid {
    width: 30vw;
    gap: 42px;
  }

  .footer-main-content-grid {
    width: 40vw;
  }

  .footer-contact-grid {
    width: 20vw;
  }

  .contact-us {
    align-items: flex-start;
  }

  .address {
    text-align: left;
  }

  .contact-footer-container {
    flex-direction: column;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .footer-logo-grid {
    width: 20vw;
  }

  .contact-footer-container {
    flex-direction: row;
  }
}

/* Large devices (laptops/desktops, 992px and up) ipad air */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
